@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
:root {
    /* Base Colors */
    --primary: #2b63ff;
    --secondary: #8b23ff;
    --primaryDark: #11296a;
    --secondaryDark: #42117d;
    --opaqueGloss: #2f3136d9;
    --gloss: #c0c0c032;
    --glass: #ffffff08;
    --shadow: #00000033;
    --subtext: #b9b9b9;

    /* Background Variants */
    --glossLight: #e0e0e04d;      /* Lighter gloss for softer transparency */
    --glossDark: #1a1a1a4d;       /* Darker gloss for more subdued elements */
    --glassLight: #ffffff1a;       /* Slightly more opaque glass effect */
    --glassDark: #0000001a;        /* Darker glass effect for a moody vibe */
    --frostedGlass: #ffffff33;     /* Frosted glass effect with more opacity */
    --frostedGlassDark: #00000033; /* Dark frosted glass effect */
    --mattGlass: #ffffff0d;        /* Matte glass with very subtle opacity */
    --mattGlassDark: #0000000d;    /* Dark matte glass */
    --metallic: #4d4d4d80;         /* Metallic sheen with partial transparency */
    --charcoal: #3a3b3f80;         /* Charcoal background with a hint of transparency */
    --smoke: #6e6e6e80;            /* Smoky, semi-transparent background */
    --chrome: #dcdcdc80;           /* Chrome-like shiny background with partial transparency */

    /* Gradient Backgrounds */
    --gradientGlass: linear-gradient(135deg, #ffffff1a, #ffffff0d); /* Gradient glass effect */
    --gradientFrost: linear-gradient(135deg, #ffffff33, #ffffff1a); /* Gradient frosted glass */
    --gradientGloss: linear-gradient(135deg, #c0c0c032, #8b23ff32); /* Gradient gloss effect */
    --gradientDarkGlass: linear-gradient(135deg, #0000001a, #42117d1a); /* Gradient dark glass */

    --gray: rgb(36, 41, 46);
    --dark_gray: rgb(31, 36, 40);
    --light_gray: rgb(40, 46, 52);

    --warning: #ff2626;

    /* VARIABLES 2.0 */

    /* COLORS */
    --darker_megenta: #230c1f;
    --dark_megenta: #301531;
    --megenta: #512251;
    --light_megenta: #d99dcc;

    --darker_cyan: #022120;
    --dark_cyan: #04302f;
    --cyan: #02534e;
    --light_cyan: #83d2cf;

    --lightest_gray: #565760;
    --ligher_gray: #4d4f58;
    --light_gray: #373943;
    --gray: #32343d;
    --dark_gray: #292b32;
    --darker_gray: #1e2025;
    --darkest_gray: #191b1f;

    --text: #f2f2f2;
    --subtext: #999999;

    --valid: #8bff8b;
    --invalid: #ff4c4c;

    --highlight: #ffffff50;
    --link: var(--light_cyan);

    --input: #acacac2a;

    /* FONT SIZES */
    --title_large: 4rem;
    --title_medium: 3.5rem;
    --title_small: 3rem;

    --header_large: 3rem;
    --header_medium: 2.5rem;
    --header_small: 2rem;

    --text: 1rem;
    --subtext: 0.8rem;

    /* FONTS */
    --title_font: 'Archivo Black', sans-serif;
    --text_font: 'Roboto', sans-serif;
}


* {
    margin: 0;
    padding: 0;
    color: white;
    font-family: Arial, sans-serif;
}
body {
    background: var(--light_gray);
}
::-webkit-scrollbar {
    width: 8px;
    background-color: var(--transparent);
}
::-webkit-scrollbar-thumb {
   background-color: var(--primary);
    border-radius: 10px;
}