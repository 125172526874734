.terms-of-service {
    margin: 0.5rem 8rem;
}
.terms-of-service h1 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: var(--text-primary);
}
.terms-of-service h2 {
    margin-top: 2rem;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    color: var(--text-primary);
}
.tos-effective {
    padding: .5rem;
    background-color: var(--darker_gray);
    width: fit-content;
    border-radius: 2rem;
}