.tooltip-parent {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) scale(0.8);
    margin-bottom: 8px;
    background-color: var(--darker_gray);
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-out, visibility 1s ease-out, transform 1s ease-out;
}

.tooltip-parent:hover .tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
    transition: opacity 1s ease-out, visibility 1s ease-out, transform 1s ease-out;
}
