.formContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  border-radius: 10px;
  background-color: var(--dark_gray);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 15px var(--lightest_gray);
  box-sizing: content-box;
  padding: 20px;
  max-height: 90%;
  overflow-y: auto;
}
h1 {
  color: #ffffff;
  font-size: var(--header_medium);
  margin: 20px 0px;
  text-align: center;
  box-sizing: border-box;
}
.formInput {
  margin: 0px 0px 15px 0px;
}
.formInput label {
  color: var(--subtext);
  display: block;
  margin-bottom: 5px;
}
.formInput input {
  width: 100%;
  padding: 0.8rem 0.5rem;
  border-radius: 5px;
  border: 1px solid #40444b;
  background-color: var(--input);
  color: var(--text);
  box-sizing: border-box;
}
.formInput input:-webkit-autofill {
  -webkit-text-fill-color: var(--text);
  background-color: var(--input);
}
.formInput input.input-error {
  border: 1px solid var(--invalid);
}
.formInput input.input-valid{
  border: 1px solid var(--valid);
}
.formInput input.input-error:focus {
  border: 1px solid var(--invalid);
}
.formInput input.input-valid:focus {
  border: 1px solid var(--valid);
}
.termsInput {
  margin: 15px 15px 15px 15px;
}
.formInput input:focus {
  border-color: var(--link);
  outline: none;
}
.formSubmit {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--secondary);
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.formSubmit:disabled {
  background-color: var(--secondaryDark);
  color: var(--subtext);
  cursor: default;
}
p {
  color: var(--subtext);
}
a {
  color: #7289da;
}
a:hover {
  text-decoration: underline;
}

.formCheckbox {
  margin-right: 0.25rem;
}

.errorMessage {
  color: red;
  height: 20px;
  margin: 10px 0;
  font-size: 0.7rem;
}









.profile-card {
  position: relative;
  background-color: var(--dark_gray);
  width: 22rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 2.5rem;
  transition: transform 0.2s ease-in-out;
  box-sizing: border-box;
}

.profile-card-modal {
  position: relative;
  background-color: var(--dark_gray);
  width: 20rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 2.5rem;
  transition: transform 0.2s ease-in-out;
}

.profile-card-banner {
  height: 10rem;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  background: linear-gradient(135deg, var(--primary), var(--secondary));
  position: relative;
}

.profile-card-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.3rem solid var(--dark_gray);
  background-color: var(--dark_gray);
  position: absolute;
  bottom: -2.5rem;
  left: 3.5rem;
  transform: translateX(-50%);
  pointer-events: none;
  -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.profile-card-displayname {
  margin-top: 3rem;
  margin-left: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.profile-card-username {
  margin: .5rem 0;
  margin-left: 1rem;
  font-weight: 100;
  font-size: 0.75rem;
  color: var(--subtext);
  text-align: left;
}

.profile-card-badges {
  margin: auto 0;
  margin-right: 1rem;
  padding: 0.25rem;
  display: flex;
  gap: 0.5rem;
  background-color: var(--darker_gray);
  border-radius: .5rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.pcb {
  margin-top: 0.25rem;
}
.profile-card-badge-staff span {
  color: #7289da;
}
.profile-card-badge-tester span {
  color: #50d762;
}
.profile-card-badge-developer span {
  color: #d550d7;
}
.profile-card-badge-verified span {
  color: #6250d7;
}
.profile-card-badge-moderator span {
  color: #d7d250;
}

.profile-card-userinfo-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.profile-card-userinfo {
  width: 100%;
  text-align: center; /* Center align for cleaner look */
}

.profile-card-bio {
  margin-top: 0;
  width: 100%;
  padding: 0.5rem;
  background-color: var(--background-tertiary);
  border-radius: 0.5rem;
  margin-left: 1rem;
  box-sizing: border-box;
}

.profile-card-bio p {
  margin: 0;
  color: var(--subtext);
  font-size: 0.7rem;
  line-height: 1.4;
}
.profile-card-creation-date-title {
  margin: 0;
  margin-left: 3rem;
  font-size: 0.75rem;
  color: var(--subtext);
  text-align: left;
  width: 100%;
}
.profile-card-creation-date,
.profile-card-uid {
  margin: 0;
  font-size: 0.75rem;
  color: var(--subtext);
  text-align: left;
  width: 100%;
  margin-left: 3rem;
  margin-bottom: .5rem;
}
.profile-card-uid {
  margin-left: 2rem;
}

.profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
}

.profile-settings {
  background-color: var(--dark_gray);
  padding: 1rem;
  border-radius: 0.8rem;
  width: 26rem;
  margin: 1.5rem 2.5rem;
  box-sizing: border-box;
}

.profile-settings h4 {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  color: var(--text-primary);
}

.profile-setting {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #23272a;
  color: #fff;
  border: 1px solid #99aab5;
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
}
.profile-setting:focus {
  outline: none;
}

.profile-settings-error {
  color: var(--error-color);
  font-size: 0.75rem;
}

.profile-settings-save {
  margin-top: 1rem;
  padding: 0.7rem;
  width: 100%;
  background-color: var(--brand-color);
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.profile-settings-save:hover {
  background-color: var(--brand-color-hover); /* Darker on hover */
}

.signout-button {
  padding: 0.7rem 1.5rem;
  background-color: var(--warning);
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}

.signout-button:hover {
  background-color: var(--warning); /* Darker on hover */
}

.profile {
  overflow-y: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border-radius: .5rem;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1;
  transition: all .15s;
}
.modal-close:hover span {
  color: var(--gray);
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 25px;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
.avatar-setting {
  position: relative;
}
.profile-settings-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.profile-settings-avatar-upload {
display: none;
}
.profile-settings-avatar:hover {
  opacity: 0.5;
  cursor: pointer;
}


/* General styles for slider containers */
.banner-selector {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

/* Styles for each color picker */
.banner-color-1,
.banner-color-2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Styles for individual color sliders */
.banner-color-1 label,
.banner-color-2 label {
  font-weight: bold;
}

/* Slider track and thumb styles */
input[type="range"] {
  appearance: none; /* Removes default styling */
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #4a90e2;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4a90e2;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #4a90e2;
  border-radius: 50%;
  cursor: pointer;
}

/* Slider rotation styles */
.banner-rotation {
  display: flex;
  flex-direction: column;
}

.banner-rotation label {
  font-weight: bold;
  margin-bottom: 5px;
}

.banner-rotation input[type="range"] {
  background: linear-gradient(to right, #4a90e2, #4a90e2) /* Customize color as needed */;
  height: 8px;
}
.banner-preview {
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  height: 100px;
  margin-top: 10px;
}