.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100vh;
  max-height: 100vh;
}
  .message {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .message .message-content {
    margin-top: 0;
    font-size: 0.9rem;
  }
  .message .message-info {
    margin: 0;
    font-size: 1rem;
    color: #888;
    margin-right: 10px;
    cursor: pointer;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  .profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }
  .message-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .timestamp {
    color: #888;
    font-size: 0.75em;
    margin-left: 5px;
  }

  .messageInteractions {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space is distributed between messages and message bar */
    height: 100vh
  }
  .messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  scrollbar-track-color: transparent;
}

.messages ul::-webkit-scrollbar-track {
  background-color: transparent;
}
  .messages ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    scrollbar-track-color: transparent;
  }
  .messages li {
    padding: 2px 8px;
    margin: 0;
    border-radius: 5px;
    word-wrap: break-word;
  }
  .message {
    margin: 0;
  }
  .messages li:hover {
    background-color: var(--glass);
  }
  .message-bar {
    display: flex;
    padding: 0px;
    background-color: var(--dark_gray);
    border-radius: 10px;
    width: 90%;
    margin: 10px auto;
    box-sizing: border-box;
  }
  .message-bar input {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    margin-right: 10px;
  }
  .message-bar input:active {
    border: none;
    background-color: transparent;
  }
  .message-bar input:focus {
   outline: none;
  }
.message-bar button {
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .message-bar button span {
    font-size: 1.75rem;
  }
  

  .message {
    position: relative;
  }
  .message-context-menu {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-100%);
    background-color: var(--dark_gray);
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
  }
  .message-context-menu button {
    padding: 1px;
    margin: 0;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    text-align: left;
  }
  .message-context-menu button:hover {
    background-color: var(--gray);
  }
  .message-context-menu:first-child button {
    border-radius: 10px;
  }
  .msg-delete-button {
    color: rgb(255, 94, 94);
  }

  .channel-banner {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .channel-banner h1 {
    margin-top: 0;
    font-size: 4rem;
    color: white;
  }
  .channel-banner h3 {
    margin-top: 10px;
    font-size: 1.5rem;
    color: var(--gloss)
  }
  .highlight {
    border-radius: 5px;
  }
  
  .message-content strong {
    font-weight: bold;
  }
  
  .sending-message .message-content {
    color: gray;
  }
.continued-message {
  margin-left: 2.5rem;
}
  .message-pfp {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: .5rem;
    float: left;
  }