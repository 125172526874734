.homepage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

.hero {
    color: #fff;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 2rem;
}

.hero-content {
    max-width: 800px;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.btn-primary {
    padding: 12px 24px;
    border-radius: 8px;
    background-color: var(--primary);
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #065c8f;
}

.features {
    padding: 4rem 2rem;
    text-align: center;
}

.features h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.feature-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.feature-card {
    background-color: var(--dark_gray);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    max-width: 300px;
}

.about {
    background-color: var(--dark_gray);
    padding: 4rem 2rem;
    text-align: center;
}

.about h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.about p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.btn-secondary {
    padding: 12px 24px;
    border-radius: 8px;
    background-color: #0984e3;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.btn-secondary:hover {
    background-color: #065c8f;
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem;
    text-align: center;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-links {
    margin-top: 1rem;
}

.footer-links a {
    color: #fff;
    margin: 0 1rem;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}
