.app-mount {
width: 100vw;
height: 100vh;
overflow: hidden;
}
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.app-content {
  width: 100%;
  height: 100%;
}
.layer-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.center {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.left {
  background: var(--dark_gray);
  width: 250px;
  height: 100vh;
  flex-grow: 0;
  flex-shrink: 0;
}
.right {
  background-color: var(--gray);
  width: 250px;
  height: 100vh;
  flex-grow: 0;
  flex-shrink: 0;
}


.user-pannel {
  display: flex;
  align-items: center;
  padding: 10px;
  background: var(--darkest_gray);
  color: #ffffff;
  border-bottom: 2px var(--darkest_gray) solid;
}

.user-pannel-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-pannel-info {
  flex: 1;
}

.user-pannel-name {
  font-weight: bold;
  margin: 0;
}

.user-pannel-status {
  font-size: 12px;
  color: #b9bbbe;
  margin: 0;
}

.user-pannel-controls {
  display: flex;
  gap: 5px;
}

.user-pannel-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}
.settings-button img {
  width: 20px;
  height: 20px;
}



/* .app {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.menu {
  background-color: var(--gloss);
  height: 100%;
  width: 4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
  }
  .menu::-webkit-scrollbar {
    width: 0;
  }
.sidebar {
  background-color: var(--glass);
  height: 100%;
  width: 16rem;
  overflow-y: scroll;
  flex-shrink: 0;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sidebar ul li {
  margin-bottom: 0rem;
  list-style-type: none;
  margin: 0 0.5rem
}
.sidebar ul li a {
  list-style-type: none;
  padding: 0.5rem;
  background-color: transparent;
  border-radius: 0.5rem;
  width: 100%;
  color: white;
  text-decoration: none;
  display: block;
  box-sizing: border-box;
}
.sidebar ul li a:hover {
  background-color: var(--gloss);
}
.sidebar div {
  list-style-type: none;
  margin: 0.5rem;
  margin-bottom: 0;
}
.chat-icon {
  margin-right: 0.5rem;
}
.sidebar div button {
  font-size: 1rem;
  list-style-type: none;
  padding: 0.5rem;
  background-color: transparent;
  border-radius: 0.5rem;
  width: 100%;
  color: white;
  text-align: left;
  text-decoration: none;
  display: block;
  box-sizing: border-box;
  border: none;
}
.sidebar div button:hover {
  background-color: var(--gloss);
}
.sidebar h2 {
  margin: .5rem 0;
  text-align: center;
  color: white;
}
.sidebar hr {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid var(--gloss);
}
.main {
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: hidden;
}
.list {
  background-color: var(--glass);
  height: 100%;
  width: 20rem;
  overflow-y: scroll;
  flex-shrink: 0;
}
.list::-webkit-scrollbar {
  width: 0;
}
.menu-btn {
  width: 3rem;
  height: 3rem;
  background-color: var(--smoke);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  margin: 0.5rem 0;
}
.menu-btn:hover {
  background-color: var(--gloss);
}
.menu-btn img {
  width: 75%;
  height: 75%;
  margin: 0 auto;
} */