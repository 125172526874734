.loading-screen {
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    height: 100vh;
    background-color: var(--gray);
    color: #fff;
}

.loading-screen-logo {
    display: block;
    width: 200px;
    height: 200px;
}

.outline {
    stroke-dasharray: 2600;
    stroke-dashoffset: 2600;
    animation: drawOutline 2s .5s forwards, fadeInFill 2s 2s forwards;
}


.flamalite-text {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: drawOutline 3s 1s forwards, fadeInFill 2s 3s forwards;
}

@keyframes drawOutline {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeInFill {
    0% {
        fill: transparent;
    }
    100% {
        fill: white;
    }
}