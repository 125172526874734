:root {
    --primaryyy: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
}

.primary {
    background-color: var(--primaryyy);
    color: #242424;
    border: 1px solid var(--primaryyy);
}

.secondary {
    background-color: #f1f1f1;
    color: #242424;
    border: 1px solid #f1f1f1;
}
.login {
    background-color: #774aff;
    color: #ffffff;
    border: 1px solid #ffffff;
}
.signup {
    background-color: #f1f1f1;
    color: #242424;
    border: 1px solid #f1f1f1;
}
.warning {
    background-color: #ff3737;
    color: #ffffff;
    border: 1px solid #ff3737;
}

.medium {
    padding: 8px 20px;
    font-size: 20px;
}

.large {
    padding: 12px 26px;
    font-size: 22px;
}
