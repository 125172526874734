.chat-list {
    width: 90%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
}

.chat-list li {
   padding: 5px 15px;
   white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    border-radius: 5px;
}
.chat-list a {
    display: block;
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chat-list li:hover {
    background-color: var(--gray);
    text-decoration: none;
}
.chatlist a:hover {
    text-decoration: none;
}
.current-chat li {
    background-color: var(--light_gray);
}

.create-chat-button {
    margin-top: 10px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    width: fit-content;
}


.create-chat-button-button {
    display: block;
    color: white;
    background-color: transparent;
    border: none;
    text-decoration: none;
    width: 2rem;
    height: 2rem;
    margin: 0px auto;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 100%;
    font-size: 1.25rem;
    cursor: pointer;
}
.create-chat-button-button:hover {
    opacity: 0.8;
}

.chat-selector {
    margin-top: 10px;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center; /* Aligns icons vertically */
}

.chat-selector-chats span,
.chat-selector-communities span {
    font-size: 2.25rem;
    cursor: pointer;
    color: #ccc; /* Set a default color */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.chat-selector-chats span:hover,
.chat-selector-communities span:hover {
    color: white; /* Change color on hover */
}

.chat-selector-underline {
    margin-top: 10px;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease, transform 0.3s ease;
}

.chat-selector-underline-active {
    width: 250%;
    transform: translateX(0); 
}

.chat-selector-chats,
.chat-selector-communities {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-selector-chats span:active,
.chat-selector-communities span:active {
    transform: scale(0.95); /* Slightly scale down on click for a pressed effect */
    transition: transform 0.1s ease;
}
