.user-list {
  width: 100%;
  padding: 10px;
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.user-list-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.user-list-item:first-child {
  padding: 0;
  margin-bottom: 5px;
}

.user-list-item:hover {
  background-color: var(--glass); /* Lighter hover color */
}
.user-list-item:first-child:hover {
  background-color: transparent; /* Lighter hover color */
}

.user-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 12px;
}

.username {
  font-size: 14px;
  color: #ffffff;
}

.owner {
  font-weight: bold;
}

.user-list-add-user {
  background-color: var(--darker_gray);
  width: 100%;
  text-align: center;
  color: #b9bbbe;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 15px 0;
  font-weight: bold;
  font-size: 14px;
  margin-top: 8px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.user-list-add-user:hover {
  background-color: var(--dark_gray);
  color: white;
}

.user-list-chat-name {
  font-size: 16px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.user-list-member-count {
  font-size: 12px;
  color: #b9bbbe;
  margin-bottom: 12px;
}

/* Status circle next to the user's avatar */
.status-circle {
  width: .75rem;
  height: .75rem;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  margin-right: 8px;
  transform: translateX(1.5rem) translateY(.65rem);
}

.online .status-circle {
  background-color: green; /* Online */
}

.offline .status-circle {
  background-color: transparent; /* Offline */
}
.offline .user-list-username {
  color: #7b7b7b;
}