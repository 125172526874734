.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal {
  background: #2c2f33; /* Dark background */
  border-radius: 10px;
  padding: 2rem;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  overflow-y: inherit;
  box-sizing: border-box;
}

.modal header {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.modal input {
  width: 100%;
  padding: 10px;
  background-color: #23272a;
  color: #fff;
  border: 1px solid #99aab5;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.modal input::placeholder {
  color: #99aab5;
}

.modal input:focus {
  outline: none;
  border-color: #7289da; /* Discord-like blue on focus */
}

.create-chat-type-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.create-chat-type-buttons button {
  background-color: #23272a;
  color: #fff;
  border: 1px solid #99aab5;
  padding: 0.5rem 1rem;
  width: 48%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.create-chat-type-buttons button:hover {
  background-color: #3c4348;
  border: 1px solid #99aab5;
}
.create-chat-type-buttons .selected {
  background-color: #7289da;
  border-color: #7289da;
}
.create-chat-type-buttons .selected:hover {
  background-color: #7289da;
  border-color: #7289da;
}

.create-chat-final-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.create-chat-final-buttons button {
  background-color: var(--darker_gray); /* Yellow color */
  font-weight: bold;
  color: #717984;
  border: none;
  padding: 0.7rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all .2s;
}
.create-chat-final-buttons button:last-child {
  font-weight: 100;
  background-color: transparent;
  color: rgb(184, 184, 184);
}

.create-chat-final-buttons button:hover {
  background-color: #7289da; 
  box-shadow: 0px 0px 10px #7289da;
  color: white;
}

.create-chat-final-buttons button:last-child:hover {
  background-color: transparent;
  color: #fff;
  box-shadow: none;
}