.navbar {
    width: 100%;
    height: 75px;
    background-color: var(--gloss);
    backdrop-filter: blur(200px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.navbar-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    height: 3rem;
    margin-right: 10px;
}

.navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbar-logo p {
    color: #E8EAED;
    font-size: var(--header_small);
    font-family: var(--title_font);
    margin: 0;
}

.navbar-buttons {
    display: flex;
    gap: 0.5rem;
}

.btn {
    padding: 8px 20px;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.btn-go-to-app {
    background-color: #00b894;
}

.btn-login, .btn-signup {
    background-color: #0984e3;
}
